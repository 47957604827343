import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
const Home = lazy(() => import("./Home"));
const Projects = lazy(() => import("./Projects"));
const Portfolio = lazy(() => import("./Portfolio"));
const About = lazy(() => import("./About"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const Contact = lazy(() => import("./Contact"));

const routesData = [
  {
    path: "/",
    exact: true,
    strict: true,
    component: Home,
  },
  {
    path: "/projects",
    exact: true,
    strict: true,
    component: Projects,
  },
  {
    path: "/portfolio",
    exact: true,
    strict: true,
    component: Portfolio,
  },
  {
    path: "/about",
    exact: true,
    strict: true,
    component: About,
  },
  {
    path: "/contact",
    exact: true,
    strict: true,
    component: Contact,
  },
  {
    path: "*",
    exact: false,
    strict: false,
    component: ErrorPage,
  },
];

export default function AllRoutes() {
  return (
    <Suspense
      fallback={
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      }
    >
      <Switch>
        {routesData.map((route) => (
          <Route
            key={route.path}
            exact={route.exact}
            // strict={route.strict}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </Suspense>
  );
}
