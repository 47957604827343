import React from "react";
import { Link } from "react-router-dom";
import "bootstrap";
import "./Navigation.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//import NavDropdown from "react-bootstrap/NavDropdown";

export default function Navigation() {
  return (
    <Navbar collapseOnSelect expand="md">
      <Navbar.Brand>SaRomSa</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-end"
        id="responsive-navbar-nav"
      >
        <Nav>
          <Nav.Link as={Link} to="/" className="nav-link">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/about" className="nav-link">
            About
          </Nav.Link>
          <Nav.Link as={Link} to="/projects" className="nav-link">
            Projects
          </Nav.Link>
          {/* <NavDropdown title="Workshops" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to="/shecodes" className="nav-link">SheCodes.io</NavDropdown.Item>
        
      </NavDropdown> */}
          <Nav.Link as={Link} to="/contact" className="nav-link">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
