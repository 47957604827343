import React, { Component } from "react";
import "./footer.css";

export let dateNow = new Date(Date.now());
export let currentYear = (date) => {
  return date.getFullYear();
};

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <small>
          <div id="copyright">
            <strong>
              &copy; 2020 - {currentYear(dateNow)} Copyright | All Rights
              Reserved
            </strong>
          </div>
        </small>
      </footer>
    );
  }
}
export default Footer;
