import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Routes from "./Components/Routes";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./Components/Navigation";
import Footer from "./Components/Footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faHome,
  faUser,
  faEnvelope,
  faExclamation,
  faList,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faHome,
  faUser,
  faEnvelope,
  faExclamation,
  faList,
  faExternalLinkAlt
);

class App extends Component {
  render() {
    return (
      <Router>
        <HelmetProvider>
          <div className="App">
            <div className="content-wrap">
            <Navigation />
            <Routes />
            </div>
          </div>
          <Footer />
        </HelmetProvider>
      </Router>
    );
  }
}

export default App;
